import ApiHelper from "../../helper/ApiHelper";

class GuestTransactionService {

    fetchGuestTransactionList(accessCode,params) {
        return ApiHelper.get(`/admin/api/v1/driver/drivertransaction?invitationCode=${accessCode}`, params,{},false);
    }


}

const GuestTransactionApi = new GuestTransactionService();
Object.freeze(GuestTransactionApi);
export default GuestTransactionApi;