import {Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";

export default function StyledLinkComponent({children,targetBlank,isLink=true,path="",sx={},...p}) {
    let linkParams={}
    if(isLink){
        linkParams= {component :Link ,to :path}
        if(targetBlank){
            linkParams.target="_blank"
        }
    }
    return <><Typography variant={"Link"} {...linkParams} sx={{cursor:"pointer",...sx}} {...p}>{children}</Typography></>
}