import React from 'react';
import {Box, CircularProgress} from "@mui/material";

function ContentLoading(props) {
    return (
        <>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            padding: "15px",
            flexDirection: "column"
        }}>
            <CircularProgress/>
        </Box></>
    );
}

export default ContentLoading;