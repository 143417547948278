import React, {useEffect, useState} from 'react';
import {Avatar, Box, CssBaseline, Stack} from "@mui/material";
import ContentFrame from "../../../../components/layout-component/content-frame";
import HeaderToolbar from "../../../../components/layout-component/header-toolbar";
import VarianHomeLogo from "../../../../../assets/png/VariablegridLogNoTagline.png";
import StyledMuiDataGrid from "../../../../components/rct-data-grid/styled-mui-data-grid";
import {
    GuestTransactionDuration,
    GuestTransactionKwh,
    GuestTransactionStopDate,
    GuestTransactionTime
} from "./component/list-column";
import {getUsers} from "./component/getUser";
import ContentLoading from "../../../../components/layout-component/content-loading";
import GuestTransactionApi from "../../../../../apis/guest-transaction/guest-transaction-service";
import uuid from "react-uuid";
import {ErrorToast} from "../../../../../Util/util";
import {mapTimeToDate} from "./helper";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

function GuestTransactions(props) {
    const params = useParams()
    const [state, setState] = useState({loading: {fetchLoading: true}})
    const {loading} = state

    const [column] = useState([
        {
            field: '_stopDate',
            headerName: 'Stop Date',
            type: "date",
            sortable: true,
            flex: 1,
            minWidth: 107,
            renderCell: (props) => {
                return <><GuestTransactionStopDate  {...props} /></>
            }
        },
        {
            field: '_timestamp', headerName: 'Time ', flex: 1, type: "date", sortable: true,
            minWidth: 80, renderCell: (props) => {
                return <><GuestTransactionTime  {...props} /></>
            }
        },
        {
            field: 'durationInSec', headerName: 'Duration', flex: 1, headerAlign: 'left', align: "left", sortable: true,
            minWidth: 100, type: 'number', renderCell: (props) => {
                return <><GuestTransactionDuration  {...props} /></>
            }
        },
        {
            field: 'powerDelivered', headerAlign: 'left', align: "left", flex: 1, headerName: 'kWh', sortable: true,
            minWidth: 80,
            type: 'number',
            renderCell: (props) => {
                return <><GuestTransactionKwh  {...props} /></>
            }
        },
    ])
    const [rows, setRows] = useState([])

    function convertSeconds(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours}.${minutes}`;
    }

    function fetchGuestTransactionList() {
        let code = params?.access_code || ""
        setState(prevState => ({...prevState, loading: {...prevState.loading, fetchLoading: true}}))
        GuestTransactionApi.fetchGuestTransactionList(code, {}).then((res) => {
            let list = res?.data?.driverTransactions || []
            try {
                list.map((row) => {
                    row.id = uuid()
                    if (row?.stopDate) {
                        row._stopDate = new Date(row?.stopDate)
                    }
                    if (row?.timestamp) {
                        row._timestamp = mapTimeToDate(row?.timestamp)
                    }
                    if (row?.durationInSec) {
                        row.duration = convertSeconds(row?.durationInSec)
                    }
                })
            } catch (e) {
                console.log(e, "ViewError>>>")
            }
            setState(prevState => ({...prevState, loading: {...prevState.loading, fetchLoading: false}}))

            setRows(list)
        }).catch((e) => {
            ErrorToast(e)
            // let list=getUsers()
            // list.map((row) => {
            //     row.id = uuid()
            //     if(row?.stopDate){
            //         row._stopDate=new Date(row?.stopDate)
            //     }
            //     if(row?.timestamp){
            //         row._timestamp=mapTimeToDate(row?.timestamp)
            //     }
            // })
            // setRows(list)
        })
    }

    useEffect(() => {
        fetchGuestTransactionList()
    }, [])

    return (
        <>
            <Helmet>
                <title>Varian Home</title>
            </Helmet>
            <CssBaseline/>
            <Box sx={{display: 'flex', background: "#F5F6FA"}}>

                <Box component="main" sx={{flexGrow: 1, p: 0, minHeight: "100%", overflow: "hidden"}}>

                    <Box sx={{height: "calc(100vh)", display: "flex", flexFlow: "column", overflow: "auto"}}>
                        <Box sx={{flex: "1 1", overflow: "hidden"}}>
                            <Box sx={{width: "100%", height: "100%", overflow: "auto"}}>
                                <ContentFrame>
                                    {loading.fetchLoading && <ContentLoading/>}
                                    {!loading.fetchLoading && <>
                                        <HeaderToolbar allowBack={false}
                                                       title={<><Stack gap={0.8} alignItems={"center"}
                                                                       flexDirection={"row"}><Avatar
                                                           variant={"square"}
                                                           src={VarianHomeLogo}
                                                           // src={Logo}
                                                           sx={{
                                                               width: 160,
                                                               // width: 20,
                                                               height: "auto",
                                                               bgcolor: "transparent",
                                                               fill: "white"
                                                           }}/></Stack></>}>

                                        </HeaderToolbar>
                                        <StyledMuiDataGrid
                                            rows={rows}
                                            columns={column}
                                            pagination={false}
                                            autoPageSize={false}
                                            hideFooter={true}
                                            gridFrameWidth={"100%"}
                                            disableColumnSorting={true}
                                            disableRowSelectionOnClick={true}
                                            rowCount={100}
                                            paginationMode={"server"}
                                            autoHeight={false}/>
                                    </>}
                                </ContentFrame>
                            </Box>
                        </Box>

                    </Box>

                </Box>
            </Box>
        </>
    );
}

export default GuestTransactions;