import React from 'react';
import {Box, useTheme} from "@mui/material";

function ContentFrame(props) {
    const theme=useTheme()
    const {children,border=false}=props
    return (
        <>
            <Box sx={{display: "flex", height: "100%", width: "100%", padding: "15px", flexDirection: "column"}}>
                <Box className={"content-frame"} sx={{width: "100%",border:border?`1px solid ${theme.palette.borderColor.main}`:"",borderRadius:"5px", height: "100%", display: "flex", flexDirection: "column", gap: "10px",
                    '& > :first-child': {
                        borderTopLeftRadius:"5px",
                        borderTopRightRadius:"5px"
                    },
                    '& > :last-child': {
                        borderBottomLeftRadius:"5px",
                        borderBottomRightRadius:"5px"
                    }
                }}>
                    {children}
                </Box>
            </Box>
        </>
    );
}

export default ContentFrame;