import React from 'react';
import LogoImage from "../../../assets/svg/Frame 37912.svg";
import SidebarImage from "../../../assets/svg/register-image.svg";
import {Box} from "@mui/material";

// import SidebarImage from "./../../../assets/png/Register image (1).png";
function CommonRightSidebar(props) {
    const {children,customLogoImage,imageWidth="130px"} = props
    return (
        <>
            <Box flex={"1 1"} sx={{
                position: "relative",
                display: "flex",
                background: "#F6FAFF",
                paddingX: "5px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{position: "absolute", width: imageWidth, left: "15px", top: "15px", zIndex: 12}}
                     src={!customLogoImage?LogoImage:customLogoImage}/>
                {/*<img style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100vh"}}*/}
                {/*     src={SidebarImage}/>*/}
                {/*<Box sx={{*/}
                {/*    width: "50%",*/}
                {/*    height: "100vh",*/}
                {/*    background: "rgba(245,245,245,.1)",*/}
                {/*    transform: " rotate(-15deg) translate(0, 25%)",*/}
                {/*    backdropFilter: "blur(5px)"*/}
                {/*}}>*/}
                {!children ?<Box sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100vh",
                    backgroundImage: `url('${SidebarImage}')`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}/>:
                    children
                }

                {/*</Box>*/}
            </Box>
        </>
    );
}

export default CommonRightSidebar;