import React from 'react';
import {useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom"
import Registration from "./routes/authorization/registration"
import ConfigPage from "./routes/guest/config/index"
import Login from "./routes/authorization/login"
import 'react-toastify/dist/ReactToastify.css';
import MuiXLicense from "./routes/components/layout-component/mui-licence";
import PrivateRoutes from "./routes/components/authorization-component/private-routes";
import ForgotPassword from "./routes/authorization/forgot-password";
import {ToastContainer} from "react-toastify";
import GuestTransactionsList from "./routes/guest/varian-home/transactions/list/guest-transactions"

function MainApp(props) {
    const authUser = useSelector((state) => (state.authUser))
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <MuiXLicense />
            <Routes>
                <Route path={"/registration/*"} element={<Registration/>}/>
                <Route path={"/config/*"} element={<ConfigPage/>}/>
                {/*<Route path={"/vh/*"} element={<VarianHome/>}/>*/}
                <Route path={"/trans/:access_code"} element={<GuestTransactionsList/>}/>
                <Route path={"/forgot-password/*"} element={<ForgotPassword/>}/>
                <Route path={"/login"} element={authUser.isAuthenticated?<Navigate to={"/app/about-companies"}/>:<Login/>}/>
                {/*<Route path={"/forget-password"} element={<Registration/>}/>*/}
                {/*{isLogin && <Route path={"/app/*"} element={<SecurePath authUser={authUser}/>}/>}*/}
                <Route path={"/*"} element={<PrivateRoutes/>} />
            </Routes>
        </>
    );
}


export default MainApp;