const getRandomUTCTime = () => {
    const now = new Date();
    const randomSeconds = Math.floor(Math.random() * 365 * 24 * 60 * 60); // Random seconds within a year
    const randomDate = new Date(now.getTime() - randomSeconds * 1000);
    return randomDate.toISOString();
    // return randomDate;
};
const getRandomTime = () => {
    const hours = Math.floor(Math.random() * 24); // Random hour (0-23)
    const minutes = Math.floor(Math.random() * 60) / 60; // Convert minutes to decimal
    return (hours + minutes).toFixed(1); // Format to 1 decimal place
};

console.log(getRandomTime()); // Example output: "14.5"

export function getUsers() {
    let arr = []
    for (let i = 0; i < 101; i++) {
        arr.push({
            id: i.toString(),
            // "stopDate": "2025-02-03T20:36:10.000Z",
            "stopDate": getRandomUTCTime(),
            // "timestamp": "2025-02-03T20:36:12.000Z",
            "timestamp": getRandomUTCTime(),
            // "duration": "1.15", //in hours, stop_datetime - start_datetime
            "duration": getRandomTime().toString(), //in hours, stop_datetime - start_datetime
            // "powerDelivered": "1.1" //in kwh
            "powerDelivered": getRandomTime().toString() //in kwh
        })
    }
    return arr
}