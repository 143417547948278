import React from 'react';
import {withWidth} from "../../../helper/layout-helper";

function DataGridFrame({gridFrameWidth=false,...props}) {
    const {children,width}=props
    return (
        <div style={{
            flex: "1 1 150px",
            overflow: width==="xxl"?"":"auto",
            // overflowX: "auto",
            // width: "calc(100vw - (270px + 15px))",
            width: !gridFrameWidth?"calc(100vw - (270px + 25px))":gridFrameWidth,
            // minHeight:"646px",
            paddingBottom:width==="xxl"?"15px":""
        }}>
            {children}
        </div>
    );
}
export default withWidth()( DataGridFrame);
// export default React.memo(DataGridFrame);