export function formatTimeDuration(time){
    time=time?.toString();
    let parts=time.split(".")
    let string=""
    if(parts?.[0]){
        string+=`${parts?.[0]}h `
    }
    if(parts?.[1]){
        string+=`${parts?.[1]}m`
    }
    return string

}
export function mapTimeToDate(utcString){
    const originalDate = new Date(utcString);
    const today = new Date();
    originalDate.setUTCFullYear(today.getUTCFullYear());
    originalDate.setUTCMonth(today.getUTCMonth());
    originalDate.setUTCDate(today.getUTCDate());

    return originalDate;
}