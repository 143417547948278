import {Typography} from "@mui/material";
import React from "react";
import {formatDateWithoutTimeZone, timeFormatter} from "../../../../../../helper/helper";
import {formatTimeDuration} from "../helper";

export function GuestTransactionStopDate(params) {
    let {row} = params
    console.log(row, "ViewRow>>>>")
    let isoDate = row?.stopDate;
    if(!isoDate){
        return  ""
    }
    let time = formatDateWithoutTimeZone(isoDate)
    return <><Typography variant={"caption"}>{isoDate?`${time?.year}-${time.month}-${time.day}`:""}</Typography></>
}

export function GuestTransactionTime(params) {
    let {row} = params
    let isoDate = row?.timestamp;
    if(!isoDate){
        return  ""
    }
    let time = formatDateWithoutTimeZone(isoDate)
    return <><Typography variant={"caption"}>{isoDate?`${time?.hours}:${time.minutes}`:""}</Typography></>
}
export function GuestTransactionDuration(params) {
    let {row} = params
    console.log(row, "ViewRow>>>>")
    let duration=formatTimeDuration(row?.duration || "")
    return <><Typography variant={"caption"}>{duration}</Typography></>
}
export function GuestTransactionKwh(params) {
    let {row} = params
    console.log(row, "ViewRow>>>>")
    return <><Typography variant={"caption"}>{row?.powerDelivered}</Typography></>
}